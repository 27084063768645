import './App.css';
import { Route, Routes } from 'react-router-dom';

import { LoginPage } from './pages/admin/LoginPage';
import UserDashboardPage from './pages/user/UserDashboardPage';
import AdminDashboardPage from './pages/admin/AdminDashboardPage';
import EmployeePage from './pages/admin/EmployeePage';
import CalendarPage from './pages/admin/CalendarPage';
import UserAutoPage from './pages/user/UserAutoPage';
import UserRemPage from './pages/user/UserRemPage';
import UserServicePage from './pages/user/UserServicePage';
import UserContactUsPage from './pages/user/UserContactUsPage';
import UserCompanyPage from './pages/user/UserCompanyPage';
import UserCareerPage from './pages/user/UserCareerPage';
import PositionPage from './pages/admin/PositionPage';
import UserTermOfUserPage from './pages/user/UserTermOfUserPage';
import UserPrivactNoticePage from './pages/user/UserPrivactNoticePage';
import ServicePage from './pages/admin/ServicePage';
import MilestonePage from './pages/admin/MilestonePage';
import { AuthProvider  } from './context/AuthContext';

const API_URL = process.env.NODE_ENV === 'production' 
  ? window.API_URL 
  : process.env.REACT_APP_API_URL;

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Routes>
            <Route exact path='/' element={<UserDashboardPage />} />
            <Route path='/auto' element={<UserAutoPage />} />
            <Route path='/real-estates-services' element={<UserRemPage />} />
            <Route path='/services' element={<UserServicePage />} />
            <Route path='/contact-us' element={<UserContactUsPage />} />
            <Route path='/company' element={<UserCompanyPage />} />
            <Route path='/careers' element={<UserCareerPage />} />
            <Route path='/term-of-use' element={<UserTermOfUserPage />} />
            <Route path='/privacy-notice' element={<UserPrivactNoticePage />} />


            <Route path='/login' element={<LoginPage />} />
            
            <Route path='/admin/dashboard' element={<AdminDashboardPage />} />
            <Route path='/admin/employee' element={<EmployeePage />} />
            <Route path='/admin/calendar' element={<CalendarPage />} />
            <Route path='/admin/hiring' element={<PositionPage />} />
            <Route path='/admin/service' element={<ServicePage />} />
            <Route path='/admin/service/milestone/:id' element={<MilestonePage />} />

        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
